jQuery(function($){

	FastClick.attach(document.body);

	$('.menu-toggle').click(function(){
		$(this).toggleClass('is-active');
		$('.menu-content').toggleClass('is-open');
	});


	// Configure the video
	if($('#video-player').length){
		var vimeoPlayer = new Vimeo.Player('video-player');

		$('.js-play').click(function(){
			if (!/mobile|tablet/i.test(navigator.userAgent)) {
				vimeoPlayer.play();
				$('.video-fullscreen').toggleClass('is-open');
				return false;
			}
		});

		$('.video-fullscreen-close').click(function(){
			vimeoPlayer.pause();
			$('.video-fullscreen').removeClass('is-open');
		});
	}
	if($('body').hasClass('schedule')){
		var date = new Date();
		current = 0;
		if(date.getMonth() === 6){
			if(date.getDate() === 21) current = 1;
			if(date.getDate() === 22) current = 2;
		}
		$('.day-toggle').click(function(e){
			e.preventDefault();
			$(this).addClass('current').siblings().removeClass('current');
			$('.schedule-day').hide().filter($(this).attr('href')).show();
		}).eq(current).click();

		if(current > 0){
			$('html,body').animate({scrollTop: $('.breadcrumbs').offset().top - 50});
		}
	}

	// Resize textarea as you type
	autosize($('textarea'));

});
